const isLogedIn = () => {
  let token = localStorage.getItem("user_token");
  let userInfo = localStorage.getItem("user_info");
  if (token)
    if (token.startsWith("logika_") && userInfo) {
      return true;
    }
  return false;
};

const getUser = () => {
  return JSON.parse(localStorage.getItem("user_info"));
};

const lougOut = () => {
  localStorage.removeItem("user_token");
  localStorage.removeItem("user_info");
  window.location.reload();
};

export { isLogedIn, lougOut, getUser };
