import "./footer.css";
import TripAdv from "src/Assets/Icons/tripadvisor_black.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookF,
  faInstagram,
  faGoogle,
} from "@fortawesome/free-brands-svg-icons";

const Footer = () => {
  return (
    <div className="footer">
      <h6> Copyright © 2022 Park Mall Hotel & Conference Center Sétif </h6>
      <div className="social_media">
        <a href="/">
          <FontAwesomeIcon icon={faFacebookF} />
        </a>
        <a href="/">
          <FontAwesomeIcon icon={faInstagram} />
        </a>
        <a href="/">
          <img src={TripAdv} alt="tripadvisor hotel park mall" />
        </a>
        <a href="/">
          <FontAwesomeIcon icon={faGoogle} />
        </a>
      </div>
    </div>
  );
};

export default Footer;
