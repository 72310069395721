export const API_URL = {
  development: "http://141.145.206.67:4200/api/",
  // production: "https://parkmallhotel.com:4200/api/",
  production: "http://141.145.206.67:4200/api/",
};

export const UPLOAD_URL = {
  development: "http://141.145.206.67:4200/api/",
  // production: "https://parkmallhotel.com:4200/media/",
  production: "http://141.145.206.67:4200/media/",
};
