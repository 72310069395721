import { Footer, Header, ServiceServices } from "src/Components";
import "./services.css";
import luxury from "src/Assets/Images/luxury.jpg";
import bg from "src/Assets/Images/Background.png";
import { useState } from "react";
import { services2Data, services3Data } from "src/Assets/Content";
import { pagesAnim } from "src/Utils/animation";
import { motion } from "framer-motion";

const Services = () => {
  const [style, setStyle] = useState(false);

  function importAll(r) {
    let images = {};
    r.keys().map((item, index) => {
      images[item.replace("./", "")] = r(item);
      return "";
    });
    return images;
  }

  const imagesServices = importAll(
    require.context(
      "../../Assets/Images/Services2",
      false,
      /\.(png|jpe?g|svg)$/
    )
  );

  return (
    <motion.div
      variants={pagesAnim}
      initial="hidden"
      animate="visible"
      exit="exit"
      className="checkservices"
    >
      <Header />

      <div className="servicesmeetlucxury">
        <div className="left">
          <img
            src={bg}
            alt="Background for effect"
            className={style ? "hoverstyle1" : "img1"}
            onMouseOver={() => setStyle((prev) => !prev)}
            onMouseOut={() => setStyle((prev) => !prev)}
          />
          <img
            src={luxury}
            alt="Luxury"
            className={style ? "hoverstyle2" : "img2"}
            onMouseOver={() => setStyle((prev) => !prev)}
            onMouseOut={() => setStyle((prev) => !prev)}
          />
        </div>
        <div className="right">
          <h3>Where Comfort Meets Luxury</h3>
          <p>
            Obtenez ce que vous recherchez avec l’élégance et le service que
            vous voulez, le tout à un prix avantageux. Nous avons ce qui importe
            le plus pour vous, notamment un lit confortable, un petit déjeuner
            exquis et du café frais. Vous trouverez également dans votre
            chambre, l’accès à l’internet gratuit
          </p>
        </div>
      </div>

      <div className="services">
        {services2Data.map((e, i) => {
          console.log(e.img, imagesServices[e.img]);
          return <ServiceServices {...e} img={imagesServices[e.img]} key={i} />;
        })}
      </div>

      <div className="services2">
        <i className="stmicon-haircut" />
        <h3>Services</h3>
        <div className="icons">
          {services3Data.map((e, i) => {
            return (
              <div className="icon" key={i}>
                <div className="ic">
                  <i className={e.icon} />
                  <span>{e.title}</span>
                </div>
                <div className="fake_ic"></div>
              </div>
            );
          })}
        </div>
      </div>

      <Footer />
    </motion.div>
  );
};

export default Services;
