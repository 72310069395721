import { Footer, Header, GallerieSlider, Loader } from "src/Components";
import { Select } from "src/Components/BasicComponents";
import { useEffect, useRef } from "react";
import HotelDatepicker from "hotel-datepicker";
import "./room.css";
import { useParams, useLocation } from "react-router-dom";
import API from "src/API";
import { useState } from "react";
import moment from "moment";
import { motion } from "framer-motion";
import { pagesAnim } from "src/Utils/animation";

const Room = () => {
  const elm = useRef(null);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const ID2 = searchParams.get("ID2");
  const { roomId } = useParams();

  const [room, setRoom] = useState(null);
  const [nbAdulte, setNbAdulte] = useState(
    localStorage.getItem("nbAdults") || 1
  );
  const [nbChildren, setNbChildren] = useState(
    localStorage.getItem("nbChildren") || 0
  );
  const [startDate, setStartDate] = useState(
    moment(new Date(localStorage.getItem("starting_date"))).format("YYYY-MM-DD")
  );
  const [finishDate, setFinishDate] = useState(
    moment(new Date(localStorage.getItem("ending_date"))).format("YYYY-MM-DD")
  );
  const [availibility, setAvailibility] = useState(null);
  const [price, setPrice] = useState(0);
  const [priceDetails, setPriceDetails] = useState([]);
  const [message, setMessage] = useState(null);
  // const [error, setError] = useState(null);

  useEffect(() => {
    API.get(`rooms/${roomId}`)
      .then((resp) => {
        setRoom(resp.data.room);
      })
      .catch((e) => {
        console.log(e);
      });
  }, [roomId]);

  useEffect(() => {
    if (room) {
      window.fecha = require("fecha");
      // API.get("pricing/available").then((resp) => {
      //   var datepicker = new HotelDatepicker(elm.current, {
      //     format: "DD MMM YYYY",
      //     infoFormat: "DD MMM YYYY",
      //     startDate: new Date(resp.data.min_start) || new Date(),
      //     endDate: new Date(resp.data.max_end) || false,
      //     // i18n,
      //   });
      //   datepicker.setValue(
      //     localStorage.getItem("starting_date") +
      //       "- " +
      //       localStorage.getItem("ending_date")
      //   );
      //   datepicker.onSelectRange = () => {
      //     console.log(datepicker.getValue());
      //     let start = datepicker.getValue().split(" - ")[0].split(" ");
      //     let s_day = start[0];
      //     let s_month = i18n["month-names-short"].indexOf(start[1]) + 1;
      //     let s_year = start[2];
      //     localStorage.setItem(
      //       "starting_date",
      //       s_month + "/" + s_day + "/" + s_year
      //     );

      //     let end = datepicker.getValue().split(" - ")[1].split(" ");
      //     let e_day = end[0];
      //     let e_month = i18n["month-names-short"].indexOf(end[1]) + 1;
      //     let e_year = end[2];
      //     localStorage.setItem(
      //       "ending_date",
      //       e_month + "/" + e_day + "/" + e_year
      //     );
      //     setStartDate(s_year + "-" + s_month + "-" + s_day);
      //     setFinishDate(e_year + "-" + e_month + "-" + e_day);
      //   };
      // });
      var datepicker = new HotelDatepicker(elm.current, {
        format: "DD MMM YYYY",
        infoFormat: "DD MMM YYYY",
        startDate: new Date(),
        // i18n,
      });
      datepicker.setValue(
        localStorage.getItem("starting_date") +
          "- " +
          localStorage.getItem("ending_date")
      );
      datepicker.onSelectRange = () => {
        console.log(datepicker.getValue());
        // let start = datepicker.getValue().split(" - ")[0].split(" ");
        // let s_day = start[0];
        // let s_month = i18n["month-names-short"].indexOf(start[1]) + 1;
        // let s_year = start[2];
        // localStorage.setItem(
        //   "starting_date",
        //   s_month + "/" + s_day + "/" + s_year
        // );

        // let end = datepicker.getValue().split(" - ")[1].split(" ");
        // let e_day = end[0];
        // let e_month = i18n["month-names-short"].indexOf(end[1]) + 1;
        // let e_year = end[2];
        // localStorage.setItem(
        //   "ending_date",
        //   e_month + "/" + e_day + "/" + e_year
        // );
        // setStartDate(s_year + "-" + s_month + "-" + s_day);
        // setFinishDate(e_year + "-" + e_month + "-" + e_day);
        let start = datepicker.getValue().split(" - ")[0];
        setStartDate(datepicker.getValue().split(" - ")[0]);
        localStorage.setItem("starting_date", start);

        let end = datepicker.getValue().split(" - ")[1];
        setFinishDate(datepicker.getValue().split(" - ")[1]);
        localStorage.setItem("ending_date", end);
      };
    }
  }, [room, startDate, finishDate]);

  useEffect(() => {
    if (room)
      API.get(`rates/rooms/available/${room?.id}`).then((resp) => {
        setAvailibility(resp.data.available);
      });
  }, [room]);

  useEffect(() => {
    if (room)
      API.post(`ratepricing/calculateprice`, {
        start_date: new Date(
          new Date(localStorage.getItem("starting_date")).setHours(12, 30, 30)
        ),
        end_date: new Date(
          new Date(localStorage.getItem("ending_date")).setHours(12, 30, 30)
        ),
        nb_adults: nbAdulte,
        nb_children: nbChildren,
        room_id: room?.id,
        rate_id: ID2,
      })
        .then((resp) => {
          let details = resp.data.details;

          if (details?.message)
            setMessage("This room isn't available for this range of dates");

          console.log(resp.data);
          setPrice(details.totalPrice);
          setPriceDetails(details.priceDetails);
        })
        .catch((e) => {});
  }, [nbAdulte, nbChildren, startDate, finishDate, room]);

  // const calcluate = () => {
  //   setError(null);
  //   if (availibility === false) {
  //     setError("Room Not Available");
  //     return;
  //   }
  //   if (nbAdulte === null) {
  //     setError("Number of guests is required");
  //     return;
  //   }
  //   if (nbChildren === null) {
  //     setError("Number of children is required");
  //     return;
  //   }

  //   setCalcLoading(true);

  //   API.post(`roomtype/pricing/calculate/`, {
  //     startDate: startDate,
  //     finishDate: finishDate,
  //     nbAdulte: nbAdulte,
  //     nbChildren :nbChildren,
  //     ratedetail_id: room?.rateDetail.id,
  //   })
  //     .then((resp) => {
  //       console.log(room?.rateDetail.id)
  //       setCalcLoading(false);
  //       setPrice(resp.data.data.price);
  //     })
  //     .catch((e) => {
  //       console.log(e);
  //     });
  // };

  return (
    <>
      {room ? (
        <motion.div
          variants={pagesAnim}
          initial="hidden"
          animate="visible"
          exit="exit"
          className="room_page"
        >
          <Header />
          <h1>{room?.description}</h1>

          {/* {console.log(room.pictures)} */}
          <div className="info_icons">
            <i className="stmicon-guest" /> {room?.max_adults}
            <i className="stmicon-nipple" /> {room?.max_children}
            {room?.nb_queen !== 0 ? (
              <>
                <i className="stmicon-bed2" /> {room?.nb_queen}
              </>
            ) : (
              <></>
            )}
            <i className="stmicon-ruler" /> {room?.size}
          </div>
          <GallerieSlider
            imagesSlider={JSON.stringify(room?.pictures.map((e) => e.filename))}
          />
          <div className="info">
            <div className="check">
              <input className="room_input" type="text" ref={elm} />
              <div
                style={{
                  zIndex: 1,
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Select
                  list={Array.from(
                    { length: room?.max_adults + room?.max_additional },
                    (_, i) => i + 1
                  )}
                  name="Guests"
                  val={nbAdulte}
                  setVal={setNbAdulte}
                />
              </div>
              <Select
                list={Array.from(
                  { length: room?.max_children + 1 },
                  (_, i) => i
                )}
                name="Childrens"
                val={nbChildren}
                setVal={setNbChildren}
              />
              {true ? (
                true ? (
                  <span className="available">Available</span>
                ) : (
                  <span className="notavailable">
                    Not available between these dates
                  </span>
                )
              ) : (
                <></>
              )}

              {/* {availibility ? (
            <div className="full_button" onClick={() => calcluate()}>
              Calculate price {">"}
            </div>
          ) : (
            <></>
          )} */}

              {/* {error !== null && error !== "" ? (
            <div className="error">{error}</div>
          ) : (
            <></>
          )} */}
              {/* {calcLoading ? <div>Loading price...</div> : <></>} */}
              {price || message ? (
                <>
                  <div className="price">
                    {" "}
                    <span>
                      {price
                        ? price?.toLocaleString("en-US", {
                            minimumFractionDigits: 2,
                          }) + "\u00A0DZD"
                        : message}{" "}
                    </span>
                  </div>
                  <div
                    className="full_button"
                    onClick={() => {
                      localStorage.setItem("nbAdult", nbAdulte);
                      localStorage.setItem("startDate", startDate);
                      localStorage.setItem("finishDate", finishDate);
                      localStorage.setItem("nbChildren", nbChildren);
                      localStorage.setItem("room", roomId);
                      localStorage.setItem("roomType", room?.description);

                      if (parseFloat(price) > 0)
                        window.location = "/checkout?ID=" + ID2;
                    }}
                  >
                    CheckOut {">"}
                  </div>
                </>
              ) : (
                <div className="room_loader">
                  <Loader />
                </div>
              )}
            </div>
            <div className="details">
              <div className="one">
                {room.rateDetails[0].rate.services.map((e, i) => {
                  return (
                    <div className="element" key={i}>
                      {e.description}
                    </div>
                  );
                })}
              </div>
              <div className="two">
                <h3>Room details</h3>
                <div className="element">
                  <span>Guests:</span>
                  <span>{room?.max_adults}</span>
                </div>
                <div className="element">
                  <span>Children:</span>
                  <span>{room?.max_children}</span>
                </div>
                <div className="element">
                  <span>Room size:</span>
                  <span>{room?.size}m²</span>
                </div>
                <div className="element">
                  <span>Room type:</span>
                  <span>{room?.description}</span>
                </div>
              </div>
              <div className="three">
                <h3>Pricing details</h3>
                <div className="head">
                  <span className="ratecode">Date</span>
                  {/* <span className="pbadult">Total Prix Adulte</span>
                  <span className="pbchildren">Total Prix Enfant</span>
                  <span className="plitadd">Total Prix Lit Additionnel</span> */}
                  <span className="pbadult">Prix de Nuitée</span>
                  {/* <span className="nbnuitee">Nombre De Nuitée</span> */}
                </div>
                {priceDetails.length ? (
                  priceDetails.map((e, i) => {
                    return (
                      <div className="element" key={i}>
                        <span className="ratecode">
                          {new Date(e.date).toLocaleDateString("fr")}{" "}
                        </span>
                        {/* <span className="pbadult">
                          {parseInt(e.price_adult).toLocaleString("en-US", {
                            minimumFractionDigits: 2,
                          })}{" "}
                          DZD
                        </span>
                        <span className="pbchildren">
                          {parseInt(e.price_children).toLocaleString("en-US", {
                            minimumFractionDigits: 2,
                          })}{" "}
                          DZD
                        </span>
                        <span className="plitadd">
                          {parseInt(e.price_extra).toLocaleString("en-US", {
                            minimumFractionDigits: 2,
                          })}{" "}
                          DZD
                        </span> */}
                        <span className="pbadult">
                          {parseFloat(
                            parseFloat(e.price_adult) +
                              parseFloat(e.price_children) +
                              parseFloat(e.price_extra)
                          ).toLocaleString("en-US", {
                            minimumFractionDigits: 2,
                          })}{" "}
                          DZD
                        </span>
                        {/* <span className="nbnuitee">{e.nb_nights}</span> */}
                      </div>
                    );
                  })
                ) : (
                  <Loader />
                )}
              </div>
            </div>
          </div>
          <Footer />
        </motion.div>
      ) : (
        <Loader />
      )}
    </>
  );
};

export default Room;
