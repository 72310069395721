import "./header.css";
import Logo from "src/Assets/Images/Logo.png";
import TripAdv from "src/Assets/Icons/tripadvisor_white.png";
import LoginPng from "src/Assets/Images/login_image.png";
import RegisterPng from "src/Assets/Images/register_image.png";
import { ReactComponent as LogoutSvg } from "src/Assets/Icons/logout.svg";
import { ReactComponent as UserSvg } from "src/Assets/Icons/user.svg";
import { ReactComponent as WalletSvg } from "src/Assets/Icons/wallet.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookF,
  faInstagram,
  faGoogle,
} from "@fortawesome/free-brands-svg-icons";
import { isLogedIn, lougOut } from "src/Utils/auth";
import { NavLink } from "react-router-dom";
import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import API from "src/API";
import { Loader } from "..";
import { getUser } from "src/Utils/auth";
import { UPLOAD_URL } from "src/constants";

const Header = () => {
  // const [active, setActive] = useState(0);
  const [minActive, setMinActive] = useState(false);
  const [displayLogin, setDisplayLogin] = useState(false);
  const [displayRegister, setDisplayRegister] = useState(false);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isAuth, setIsAuth] = useState(isLogedIn());

  const refLogin = useRef();
  const refRegister = useRef();

  useEffect(() => {
    let handleClickOutsideLogin = (e) => {
      if (refLogin.current && !refLogin.current.contains(e.target)) {
        setDisplayLogin(false);
      }
    };

    let handleClickOutsideRegister = (e) => {
      if (refRegister.current && !refRegister.current.contains(e.target)) {
        setDisplayRegister(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutsideLogin);
    document.addEventListener("mousedown", handleClickOutsideRegister);

    return () => {
      document.removeEventListener("mousedown", handleClickOutsideLogin);
      document.removeEventListener("mousedown", handleClickOutsideRegister);
    };
  }, []);

  const navigate = useNavigate();
  const [loadingRegister, setLoadingRegister] = useState(false);
  const [loadingLogin, setLodingLogin] = useState(false);
  const [RegisterError, setRegisterError] = useState("");
  const [loginError, setLoginError] = useState("");

  const postLogin = () => {
    setLodingLogin(true);
    setLoginError("");
    API.post("users/login", { email, password })
      .then((resp) => {
        localStorage.setItem("user_token", resp.data.token);
        localStorage.setItem("user_info", JSON.stringify(resp.data.user));
        setDisplayLogin(false);
        setIsAuth(isLogedIn());
        setLoginError("");
        setLodingLogin(false);
        window.location.reload();
      })
      .catch((err) => {
        setLoginError(
          err.response.data.message + ", please check again your inputs."
        );
        setLodingLogin(false);
      });
  };

  const postRegister = () => {
    setLoadingRegister(true);
    setRegisterError("");
    API.post("users", {
      firstname: firstName,
      lastname: lastName,
      email: email,
      phone: phone,
      password: password,
    })
      .then((resp) => {
        if (resp.data?.message) {
          setRegisterError(resp.data.message);
        } else if (resp.data?.token?.length) {
          localStorage.setItem("user_token", resp.data.token);
          localStorage.setItem("user_info", JSON.stringify(resp.data.user));
          setDisplayRegister(false);
          setIsAuth(isLogedIn());
          setRegisterError("");
        } else {
          setRegisterError(
            "All the fields above are required, please check again your inputs."
          );
        }
        setLoadingRegister(false);
      })
      .catch((err) => {
        setRegisterError(
          "All the fields above are required, please check again your inputs. If the error presist try again later."
        );
        setLoadingRegister(false);
      });
  };

  return (
    <div
      className={!minActive ? "header" : "header active"}
      style={{ zIndex: 10 }}
    >
      {displayLogin && !isAuth ? (
        <div className="login_blur_wrrapper">
          <div className="login_form" style={{ zIndex: 10 }} ref={refLogin}>
            <img src={LoginPng} />
            <form className="form_info">
              <h4>Sign in</h4>
              <p>
                Welcome back! Please sign in to access your reservation and
                account information.
              </p>
              <div className="login_inputs">
                <input
                  placeholder="Email or Phone number"
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                />
                <input
                  placeholder="Password"
                  type="password"
                  onChange={(e) => {
                    setPassword(e.target.value);
                  }}
                />
              </div>
              <div className="login_under_input">
                <div className="login_remember_me">
                  <input type="checkbox" />
                  <span>Remember me</span>
                </div>
                <span>Forgot password ?</span>
              </div>
              <span className="errormessageform">{loginError}</span>
              {!loadingLogin ? (
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    postLogin();
                  }}
                >
                  Login
                </button>
              ) : (
                <Loader />
              )}
              <span>
                Don’t have an account?{" "}
                <b
                  onClick={() => {
                    setDisplayLogin(false);
                    setDisplayRegister(true);
                  }}
                >
                  Register
                </b>
              </span>
              <div className="close_login">
                <span
                  onClick={() => {
                    setDisplayLogin(false);
                  }}
                >
                  X Close
                </span>
              </div>
            </form>
          </div>
        </div>
      ) : (
        <></>
      )}

      {displayRegister && !isAuth ? (
        <div className="login_blur_wrrapper">
          <div
            className="register_form"
            style={{ zIndex: 10 }}
            ref={refRegister}
          >
            <img src={RegisterPng} />
            <form>
              <h4>Register</h4>
              <p>
                Join our loyalty program and enjoy exclusive benefits. Register
                now!
              </p>
              <div className="register_inputs">
                <input
                  placeholder="First name"
                  onChange={(e) => {
                    setFirstName(e.target.value);
                  }}
                />
                <input
                  placeholder="Last name"
                  onChange={(e) => {
                    setLastName(e.target.value);
                  }}
                />
                <input
                  placeholder="Phone number"
                  onChange={(e) => {
                    setPhone(e.target.value);
                  }}
                />
                <input
                  placeholder="Email"
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                />
                <input
                  placeholder="Password"
                  type="password"
                  onChange={(e) => {
                    setPassword(e.target.value);
                  }}
                />
              </div>
              <span className="errormessageform">{RegisterError}</span>
              {!loadingRegister ? (
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    postRegister();
                  }}
                >
                  Register
                </button>
              ) : (
                <Loader />
              )}
              <span>
                Already have an account ?{" "}
                <b
                  onClick={() => {
                    setDisplayRegister(false);
                    setDisplayLogin(true);
                  }}
                >
                  Login
                </b>
              </span>
              <div className="close_login">
                <span
                  onClick={() => {
                    setDisplayRegister(false);
                  }}
                >
                  X Close
                </span>
              </div>
            </form>
          </div>
        </div>
      ) : (
        <></>
      )}

      <div className="header_logo">
        <img
          src={Logo}
          alt="Hotel Park Mall Logo"
          onClick={() => {
            navigate("/");
          }}
        />
      </div>
      <div className="header_elements">
        <ul>
          <li
            onClick={() => {
              setMinActive((prev) => !prev);
            }}
          >
            <NavLink
              to="/"
              end
              className={({ isActive }) => (isActive ? "selected" : "")}
            >
              <span>Accueil</span>
            </NavLink>
          </li>
          <li
            onClick={() => {
              setMinActive((prev) => !prev);
            }}
          >
            <NavLink
              to="/chambres"
              className={({ isActive }) => (isActive ? "selected" : "")}
            >
              <span>Chambres</span>
            </NavLink>
          </li>
          {/* <li
            onClick={() => {
              setMinActive((prev) => !prev);
            }}
          >
            <NavLink
              to="/conferences"
              className={({ isActive }) => (isActive ? "selected" : "")}
            >
              <span>Conference</span>
            </NavLink>
          </li> */}
          <li
            onClick={() => {
              setMinActive((prev) => !prev);
            }}
          >
            <NavLink
              to="/services"
              className={({ isActive }) => (isActive ? "selected" : "")}
            >
              <span>Services</span>
            </NavLink>
          </li>
          <li
            onClick={() => {
              setMinActive((prev) => !prev);
            }}
          >
            <NavLink
              to="/gallerie"
              className={({ isActive }) => (isActive ? "selected" : "")}
            >
              <span>Gallerie</span>
            </NavLink>
          </li>
          <li
            onClick={() => {
              setMinActive((prev) => !prev);
            }}
          >
            <NavLink
              to="/contact"
              className={({ isActive }) => (isActive ? "selected" : "")}
            >
              <span>Contact</span>
            </NavLink>
          </li>
          {isAuth ? (
            <div className="mini_login_navbar_full">
              <div className="mini_login_navbar_info">
                <span>{getUser().firstname + " " + getUser().lastname}</span>
                {getUser().profile_picture ? (
                  <img
                    src={`${UPLOAD_URL[process.env.NODE_ENV]}/users/${
                      getUser().profile_picture.filename
                    }`}
                    alt="profile picture"
                  />
                ) : (
                  <img src="https://picsum.photos/200/300" />
                )}
                <ul>
                  <li>
                    <UserSvg />
                    <span
                      onClick={() => {
                        navigate("/profile");
                      }}
                    >
                      Profile
                    </span>
                  </li>
                  <li>
                    <WalletSvg />
                    <span
                      onClick={() => {
                        navigate("/profile?reservations=selected");
                      }}
                    >
                      Reservations
                    </span>
                  </li>
                  <li
                    onClick={() => {
                      lougOut();
                      setIsAuth(isLogedIn());
                    }}
                  >
                    <LogoutSvg />
                    <span>Logout</span>
                  </li>
                </ul>
              </div>
            </div>
          ) : (
            <div className="mini_login_navbar_info">
              <span
                className="login_button"
                onClick={() => {
                  setDisplayLogin(true);
                }}
              >
                Login
              </span>
              <span
                className="register_button"
                onClick={() => {
                  setDisplayRegister(true);
                }}
              >
                Register
              </span>
            </div>
          )}
        </ul>
      </div>
      {/* <div className={minActive ? "social_media" : "social_media active"}>
        <a href="/">
          <FontAwesomeIcon icon={faFacebookF} />
        </a>
        <a href="/">
          <FontAwesomeIcon icon={faInstagram} />
        </a>
        <a href="/">
          <img src={TripAdv} alt="tripadvisor hotel park mall" />
        </a>
        <a href="/">
          <FontAwesomeIcon icon={faGoogle} />
        </a>
      </div> */}

      {isAuth ? (
        <div className="login_navbar_full">
          <div className="login_navbar_info">
            <span>{getUser().firstname + " " + getUser().lastname}</span>
            {getUser().profile_picture ? (
              <img
                src={`${UPLOAD_URL[process.env.NODE_ENV]}/users/${
                  getUser().profile_picture.filename
                }`}
                alt="profile picture"
              />
            ) : (
              <img src="https://picsum.photos/200/300" />
            )}
            <ul>
              <li>
                <UserSvg />
                <span
                  onClick={() => {
                    navigate("/profile");
                  }}
                >
                  Profile
                </span>
              </li>
              <li>
                <WalletSvg />
                <span
                  onClick={() => {
                    navigate("/profile?reservations=selected");
                  }}
                >
                  Reservations
                </span>
              </li>
              <li
                onClick={() => {
                  lougOut();
                  setIsAuth(isLogedIn());
                }}
              >
                <LogoutSvg />
                <span>Logout</span>
              </li>
            </ul>
          </div>
        </div>
      ) : (
        <div className="login_navbar_info">
          <span
            className="login_button"
            onClick={() => {
              setDisplayLogin(true);
            }}
          >
            Login
          </span>
          <span
            className="register_button"
            onClick={() => {
              setDisplayRegister(true);
            }}
          >
            Register
          </span>
        </div>
      )}
      <div className="burgermenu" onClick={() => setMinActive((prev) => !prev)}>
        <div className="line" />
        <div className="line" />
      </div>
    </div>
  );
};

export default Header;
