import "./home.css";
import {
  Header,
  Room,
  Slider,
  GallerieSliderV2,
  FeedBack,
  Gomap,
  CheckOutRateHome,
  ServiceHome,
  Footer,
  // Loader,
} from "src/Components";
import API from "src/API";

import { LazyLoadImage } from "react-lazy-load-image-component";

import { motion } from "framer-motion";

import { useNavigate } from "react-router-dom";

import { Item } from "src/Components/BasicComponents";

import {
  RoomsData,
  servData,
  WhyUsData,
  feedbackData,
  itemsData,
} from "src/Assets/Content";

import lastBG from "src/Assets/Images/LastBackground.jpg";

import { useState } from "react";
import { useEffect } from "react";
import { pagesAnim } from "src/Utils/animation";
import { useLocation } from "react-router-dom";
import { lougOut } from "src/Utils/auth";

const Home = () => {
  const [roomType, setRoomType] = useState(1);
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();

  //functions
  function importAll(r) {
    let images = {};
    r.keys().map((item, index) => {
      images[item.replace("./", "")] = r(item);
      return "";
    });
    return images;
  }

  const images = importAll(
    require.context("../../Assets/Images/Services", false, /\.(png|jpe?g|svg)$/)
  );

  const imagesRooms = importAll(
    require.context("../../Assets/Images/Rooms", false, /\.(png|jpe?g|svg)$/)
  );

  const images_names = Object.keys(images);

  const title_name = images_names.map((e) =>
    e.replace(".jpg", "").replace("png", "")
  );

  // useEffect(() => {
  //   API.get("content/slide").then((resp) => {
  //   });
  //   setLoading(false);
  // }, []);

  //return

  const location = useLocation();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const clear = searchParams.get("clear");
    if (clear) {
      lougOut();
      window.location.href = "/";
    }
  }, []);

  return (
    <motion.div
      variants={pagesAnim}
      initial="hidden"
      animate="visible"
      exit="exit"
      className="home"
    >
      <div className="slider_show">
        <Header />
        <Slider />
        <div className="checkoutandcontent">
          <CheckOutRateHome />
        </div>
      </div>

      <div className="services">
        <i className="stmicon-haircut" />
        <h3>Nos Services</h3>
        {images_names.map((e, i) => {
          return (
            <ServiceHome
              img={images[e]}
              data={servData[title_name[i]]}
              i={i}
              key={i}
            />
          );
        })}
      </div>

      <div className="rooms">
        <i className="stmicon-haircut" />
        <h3>Chambres & Suites</h3>
        <ul>
          <li
            className={roomType === 1 ? "selected" : ""}
            onClick={() => {
              setRoomType(1);
            }}
          >
            Standard
          </li>
          <li
            className={roomType === 2 ? "selected" : ""}
            onClick={() => {
              setRoomType(2);
            }}
          >
            Deluxe
          </li>
          <li
            className={roomType === 3 ? "selected" : ""}
            onClick={() => {
              setRoomType(3);
            }}
          >
            Business
          </li>
          <li
            className={roomType === 4 ? "selected" : ""}
            onClick={() => {
              setRoomType(4);
            }}
          >
            Suite
          </li>
        </ul>
        <div className="rooms_types">
          {roomType === 1 &&
            RoomsData["standard"].map((e, i) => {
              return (
                <Room
                  img={imagesRooms[e.img]}
                  type={e.type}
                  price={e.price}
                  icons={e.icons}
                  delay={i}
                  key={i}
                />
              );
            })}
          {roomType === 2 &&
            RoomsData["deluxe"].map((e, i) => {
              return (
                <Room
                  img={imagesRooms[e.img]}
                  type={e.type}
                  price={e.price}
                  icons={e.icons}
                  delay={i}
                  key={i}
                />
              );
            })}
          {roomType === 4 &&
            RoomsData["suite"].map((e, i) => {
              return (
                <div
                  className="room_action_wrapper"
                  onClick={() => {
                    window.location += "chambres";
                  }}
                >
                  <Room
                    bol={true}
                    img={imagesRooms[e.img]}
                    type={e.type}
                    price={e.price}
                    icons={e.icons}
                    delay={i}
                    key={i}
                  />{" "}
                </div>
              );
            })}
        </div>
      </div>

      <div className="whyus">
        <i className="stmicon-haircut" />
        <h3>Pourquoi Nous Choisir</h3>
        <h4>
          Notre hôtel dispose des services les plus luxueux de toute la ville de
          Sétif
        </h4>
        <div className="icons">
          {WhyUsData.map((e, i) => {
            return (
              <div className="icon" key={i}>
                <div className="ic">
                  <i className={e.icon} />
                </div>
                <span>{e.title}</span>
              </div>
            );
          })}
        </div>
      </div>

      <div className="gallerie">
        <i className="stmicon-haircut" />
        <h3>Gallerie</h3>
        <GallerieSliderV2 />
      </div>

      <div className="avis">
        <i className="stmicon-haircut" />
        <h3>Avis Des Clients</h3>
        <div className="feedbacks">
          {feedbackData.map((e, i) => {
            return <FeedBack {...e} key={i} />;
          })}
        </div>
        <button>
          <i className="stmicon-rate_review" /> Rédiger un avis
        </button>
      </div>

      <div className="location">
        <div className="left">
          <h3>
            Au <span>Centre</span> de tous vos intêrets
          </h3>

          <div className="items">
            {itemsData.map((e, i) => {
              return <Item {...e} key={i} />;
            })}
          </div>
        </div>
        <div className="right">
          <Gomap />
        </div>
      </div>

      <div className="reservez">
        <div>
          <LazyLoadImage src={lastBG} alt="Reserver maintenant" />
        </div>
        <i className="stmicon-haircut" />
        <h3>RESERVEZ MAINTENANT</h3>
        <div
          className="outlined_button"
          onClick={() => {
            navigate("/chambres");
          }}
        >
          RESERVER UNE CHAMBRE
        </div>
      </div>

      <Footer />
    </motion.div>
  );
};

export default Home;
