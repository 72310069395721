import {
  Home,
  CheckRooms,
  Services,
  Contact,
  Room,
  PaymentForm,
  PaymentCheck,
  PaymentError,
  Gallerie,
  Conferences,
  Conference,
  Error404,
  Profile,
  // PaymentSuccess,
} from "./Pages";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Policy from "./Pages/policy/policy";
import PyamentRedirect from "./Pages/Payment/Redirect/RedirectToPayment";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route index element={<Home />} />
        <Route path="/chambres">
          <Route index element={<CheckRooms />} />
          <Route path=":roomId" element={<Room />} />
        </Route>
        <Route path="/conferences">
          <Route index element={<Conferences />} />
          <Route path=":confId" element={<Conference />} />
        </Route>
        <Route path="/policy" element={<Policy />} />
        <Route path="/services" element={<Services />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/checkout" element={<PaymentForm />} />
        <Route path="/gallerie" element={<Gallerie />} />
        <Route path="/checkreservation" element={<PaymentCheck />} />
        <Route path="/payment">
          <Route path="confirm/:orederId" element={<PyamentRedirect />} />
          <Route path="failure" element={<PaymentError />} />
          {/* <Route path="success" element={<PaymentSuccess />} /> */}
        </Route>
        <Route path="/profile" element={<Profile />} />
        <Route path="*" element={<Error404 />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
