import "./paymentform.css";
import { Header, Footer } from "src/Components";
import { useEffect, useState } from "react";
import API from "src/API";
import ReCAPTCHA from "react-google-recaptcha";

import { getUser } from "src/Utils/auth";

import Satim from "src/Assets/Images/satim.png";
import Cib from "src/Assets/Images/cib.png";

const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
const nameRegex = /^[a-z]+$/i;
const phoneRegex =
  /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/;
const timeRegex = /^([0-1]?[0-9]|2[0-3])$/;

const ErrorModal = ({ setErrorActive }) => {
  return (
    <div className="errormodal">
      <div className="wrapperblur">
        <div className="content">
          <h3>
            Something went wrong, Check your inputs & make sure you check the
            accept conditions box.
          </h3>
          <div className="buttons">
            <div
              className="fullbutton cancel"
              onClick={() => {
                setErrorActive(false);
              }}
            >
              Close
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const PaymentForm = () => {
  const searchParams = new URLSearchParams(window.location.search);
  const ID = searchParams.get("ID");

  const [fname, setFname] = useState(getUser()?.firstname ?? "");
  const [lname, setLname] = useState(getUser()?.lastname ?? "");
  const [email, setEmail] = useState(getUser()?.email ?? "");
  const [phone, setPhone] = useState(getUser()?.phone ?? "");
  const [arrival, setArrival] = useState("");
  const [requests, setRequests] = useState("");
  const [loading, setLoading] = useState(false);
  const [online, setOnline] = useState(null);
  const [success, setSuccess] = useState(false);

  const [errP, setErrP] = useState();
  const [errNf, setErrNf] = useState();
  const [errEm, setErrEm] = useState();
  const [errPh, setErrPh] = useState();

  const [captcha, setCaptcha] = useState(false);
  const [conditions, setConditions] = useState(false);

  const [price, setPrice] = useState("Calculating Price");

  const [errorActive, setErrorActive] = useState(false);

  const [onlinePayment, setOnlinePayment] = useState(true);
  const [hotelPayment, setHotelPayment] = useState(true);

  useEffect(() => {
    API.post(`ratepricing/calculateprice`, {
      start_date: new Date(localStorage.getItem("startDate")),
      end_date: new Date(localStorage.getItem("finishDate")),
      nb_adults: parseInt(localStorage.getItem("nbAdult")),
      nb_children: parseInt(localStorage.getItem("nbChildren")),
      room_id: localStorage.getItem("room"),
      rate_id: ID,
    }).then((resp) => {
      console.log(resp.data.details);
      setPrice(resp.data.details.totalPrice);
    });

    API.get("config").then((resp) => {
      setHotelPayment(resp.data.configs[0].value);
      setOnlinePayment(resp.data.configs[1].value);
    });

    // API.get("reservation/check").then((resp) => {
    //   setNotOnlinePossible(!resp.data.data.needPayOnline);
    // });
  }, []);

  const post = () => {
    if (
      !nameRegex.test(fname) ||
      !nameRegex.test(lname) ||
      !emailRegex.test(email) ||
      !phoneRegex.test(phone) ||
      !conditions
    ) {
      setErrorActive(true);
    } else {
      setLoading(true);
      API.post("reservations", {
        checkin_date: new Date(localStorage.getItem("startDate")),
        checkout_date: new Date(localStorage.getItem("finishDate")),
        nb_adults: parseInt(localStorage.getItem("nbAdult")),
        nb_children: parseInt(localStorage.getItem("nbChildren")),
        room_id: localStorage.getItem("room"),
        firstname: fname,
        lastname: lname,
        email: email,
        phone: phone,
        arrival_time: "11",
        more: requests,
        online,
        rate_id: ID,
        token: localStorage.getItem("user_token"),
      })
        .then((resp) => {
          setTimeout(() => {
            if (online)
              window.location = resp.data.reservation.payment.form_url;
            else setSuccess(true);
          }, 1000);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  return (
    <div className="paymentform">
      <Header />
      <div className="container">
        {errorActive && <ErrorModal setErrorActive={setErrorActive} />}
        <div className="left">
          <h3>Réservation Directe</h3>
          <div>
            <i className="stmicon-phone" /> <span>036 81 41 41(42)</span>
          </div>
          <div>
            <i className="stmicon-email" />{" "}
            <span>reservation@parkmallhotel.com</span>
          </div>
        </div>
        <div className="right">
          <form>
            <div className="input">
              <span>Prénom</span>
              <input
                placeholder="Prénom"
                value={fname}
                onChange={(e) => {
                  if (/^[a-zA-Z]*$/.test(e.target.value))
                    setFname(e.target.value);
                }}
                onBlur={() => {
                  console.log(nameRegex.test(fname));
                  setErrP(nameRegex.test(fname));
                }}
                className={fname === "" ? (errP ? "" : "error") : ""}
              />
            </div>
            <div className="input">
              <span>Nom de famille </span>
              <input
                placeholder="Nom de famille "
                value={lname}
                onChange={(e) => {
                  setLname(e.target.value);
                }}
                onBlur={() => {
                  setErrNf(nameRegex.test(lname));
                }}
                className={lname === "" ? (errP ? "" : "error") : ""}
              />
            </div>
            <div className="input">
              <span>Adresse e-mail</span>
              <input
                placeholder="Adresse e-mail"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
                onBlur={() => {
                  setErrEm(emailRegex.test(email));
                }}
                className={email === "" ? (errP ? "" : "error") : ""}
              />
            </div>
            <div className="input">
              <span>Téléphone </span>
              <input
                placeholder="Téléphone"
                value={phone}
                onChange={(e) => {
                  if (
                    e.target.value.length < 16 ||
                    e.target.value === "" ||
                    Number(e.target.value) === 0
                  )
                    setPhone(e.target.value);
                }}
                onBlur={() => {
                  setErrPh(phoneRegex.test(phone));
                }}
                className={phone === "" ? (errP ? "" : "error") : ""}
              />
            </div>
            <div className="input">
              <span>Time of arrival</span>
              <input
                placeholder="14"
                value={arrival}
                onChange={(e) => {
                  if (
                    (e.target.value.length < 3 &&
                      Number(e.target.value) &&
                      timeRegex.test(e.target.value)) ||
                    e.target.value === "" ||
                    Number(e.target.value) === 0
                  )
                    setArrival(e.target.value);
                }}
              />
              <span className="desc">
                L’heure correspond au fuseau horaire Sétif
              </span>
            </div>
            <div className="input full">
              <span>Demandes spéciales</span>
              <textarea
                rows={4}
                value={requests}
                onChange={(e) => {
                  setRequests(e.target.value);
                }}
              />
              <span className="desc">
                Les demandes spéciales ne peuvent pas être garanties, mais nous
                ferons de notre mieux pour répondre à vos besoins.
              </span>
            </div>
          </form>
          <div className="bookingdetails">
            <h3>Booking details</h3>
            <div className="detail">
              <span>Check-in</span>
              <span>{localStorage.getItem("startDate")} , 15:00</span>
            </div>
            <div className="detail">
              <span>Check-out</span>
              <span>{localStorage.getItem("finishDate")} , 12:00</span>
            </div>
            {/* <div className="detail">
              <span>Pets</span>
              <span>Les animaux ne sont pas acceptés.</span>
            </div> */}
          </div>
          <div className="yourreservation">
            <h3>Booking details</h3>
            <div className="head">
              <span className="rm">Type de Chambre</span>
              <span className="nbadult">Nombre d'Adultes</span>
              <span className="nbchildren">Nombre d'Enfants</span>
              <span className="nb nights">Nombre de nuits</span>
            </div>
            <div className="element">
              <span className="rm">{localStorage.getItem("roomType")}</span>
              <span className="nbadult">{localStorage.getItem("nbAdult")}</span>
              <span className="nbchildren">
                {localStorage.getItem("nbChildren")}
              </span>
              <span className="nb nights">
                {(new Date(localStorage.getItem("finishDate")) -
                  new Date(localStorage.getItem("startDate"))) /
                  (24 * 60 * 60 * 1000)}
              </span>
            </div>
            <div className="total">
              <span>Total:</span>
              <span>{price + " DZD"}</span>
            </div>
          </div>
          {hotelPayment === "false" && onlinePayment === "false" ? (
            <h2>
              Vous ne pouvez pas réserver via le site Web, veuillez contacter
              nous en utilisant les numéros ci-dessus
            </h2>
          ) : (
            <h2>Choisir la Methode de paiment :</h2>
          )}
          <div className="paymentmethod">
            {/* {notOnlinePossible && ( */}
            {hotelPayment !== "false" && (
              <span
                onClick={() => {
                  setOnline(false);
                }}
                className={online === false ? "active" : ""}
              >
                Payez en arrivant à l'hôtel
              </span>
            )}
            {/* )} */}
            {onlinePayment !== "false" && (
              <span
                onClick={() => {
                  setOnline(true);
                }}
                className={online ? "active" : ""}
              >
                Payement Online en utilisant une carte CIB
                <img src={Satim} alt="satim e-payment" />
              </span>
            )}
          </div>
          {online !== null && (
            <>
              <div className="paymentmethod">
                <ReCAPTCHA
                  sitekey="6LeXb-giAAAAAG71wisSuLOKb5Mr9C843zrX3nXu"
                  onChange={(val) => {
                    setCaptcha(true);
                  }}
                />
              </div>
              <p>
                Vos données personnelles seront utilisées pour soutenir votre
                expérience sur ce site Web et pour traiter vos réservations
              </p>

              <p>
                Accepter{" "}
                <a href="/policy" target="_blank">
                  {" "}
                  les condition d'utilisation{" "}
                </a>
                <input
                  type="checkbox"
                  onChange={(e) => {
                    setConditions((prev) => !prev);
                  }}
                />
              </p>
              {online ? (
                loading ? (
                  "redirecting you to the paiement portal"
                ) : (
                  <button
                    disabled={!captcha}
                    onClick={() => {
                      post();
                    }}
                  >
                    Réserver maintenant
                    <img src={Cib} alt="Cib/Edahabia Logo" />
                  </button>
                )
              ) : !success ? (
                <button
                  disabled={!captcha}
                  onClick={() => {
                    post();
                  }}
                >
                  Réserver maintenant
                </button>
              ) : (
                <h5>Reservation complete</h5>
              )}
            </>
          )}
        </div>
      </div>
      {console.log(conditions)}
      <Footer />
    </div>
  );
};

export default PaymentForm;
