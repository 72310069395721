import "./checkrooms.css";
import { Footer, Header, Loader } from "src/Components";
import { CheckBoxSelection } from "src/Components/BasicComponents";
import { useState } from "react";
import { Room, CheckOutRateChambre } from "src/Components";
// import { RoomsData } from "src/Assets/Content";
import { useEffect } from "react";
import API from "src/API";
import { useLocation } from "react-router-dom";
import { API_URL, UPLOAD_URL } from "src/constants";
import { motion, AnimatePresence } from "framer-motion";
import { pagesAnim } from "src/Utils/animation";
import { useRef } from "react";

const CheckRooms = () => {
  //for test to be removed once we inderstand the process
  // const [dumb, setDumb] = useState(0);

  // const [delux, setDelux] = useState(false);
  // const [standard, setStandard] = useState(false);
  // const [suite, setSuite] = useState(false);

  // const [nbAdulte, setNbAdulte] = useState(0);
  // const [nbEnfant, setNbEnfants] = useState(0);

  const isMountRef = useRef(true);

  useEffect(() => {
    isMountRef.current = false;
  }, []);

  const cbs0 = {
    title: "Type de chambre",
    filters: [
      {
        name: "Delux",
        // stateFunc: setDelux,
      },
      {
        name: "Standard",
        // stateFunc: setStandard,
      },
      {
        name: "Suite",
        // stateFunc: setSuite,
      },
    ],
  };

  const cbs2 = {
    title: "Clients",
    filters: [
      {
        name: "1",
        // stateFunc: setDelux,
      },
      {
        name: "2",
        // stateFunc: setStandard,
      },
      {
        name: "3",
        // stateFunc: setDelux,
      },
    ],
  };

  const cbs3 = {
    title: "Enfants",
    filters: [
      {
        name: "1",
        // stateFunc: setDelux,
      },
      {
        name: "2",
        // stateFunc: setStandard,
      },
      {
        name: "3",
        // stateFunc: setStandard,
      },
    ],
  };

  const [rooms, setRooms] = useState(null);
  const [rateId, setRateId] = useState(null);
  const [offersRooms, setOffersRooms] = useState(null);
  // const [packages, setPackages] = useState(null);
  const [typeQuery, setTypeQuery] = useState({
    Standard: true,
    Delux: true,
    Suite: true,
  });
  const [nbAdulteQuery, setNbAdulteQuery] = useState(3);
  const [nbChildrensQuery, setNbChildrensQuery] = useState(3);
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState("");

  // function importAll(r) {
  //   let images = {};
  //   r.keys().map((item, index) => {
  //     images[item.replace("./", "")] = r(item);
  //     return "";
  //   });
  //   return images;
  // }

  // const imagesRooms = importAll(
  //   require.context("../../Assets/Images/Rooms", false, /\.(png|jpe?g|svg)$/)
  // );

  // useEffect(() => {
  //   console.log(location.state);
  //   if (location.state !== null && location.state.currentRate) {
  //     // the second part of the condition was added by me to fix the broken thing
  //     setRooms([
  //       ...location.state.currentRate.rateDetails,
  //       // ...location.state.packages.map((e) => e.rateDetails),
  //     ]);
  //     setPackages(...location.state.packages?.map((e) => e?.rateDetails));
  //     setLoading(false);
  //   } else
  //     API.get("rates/active")
  //       .then((resp) => {
  //         console.log(resp.data.rate);
  //         // const pK = resp.data.data.packages;
  //         // if (pK.length === 0) {
  //         setRooms([...resp.data.rate.details]);
  //         // setPackages(...resp.data.data.packages?.map((e) => e?.rateDetails));
  //         // } else {
  //         //   setRooms([
  //         //     ...resp.data.data.currentRate.rateDetails,
  //         //     // ...resp.data.data.packages.map((e) => e.rateDetails),
  //         //   ]);
  //         // }
  //         setLoading(false);
  //       })
  //       .catch((e) => {
  //         console.log(e);
  //       });
  // }, []);

  useEffect(() => {
    var params = new URLSearchParams();

    if (typeQuery.Standard) params.append("types", "STD");
    if (typeQuery.Delux) params.append("types", "DLD");
    if (typeQuery.Delux) params.append("types", "DLS");
    if (typeQuery.Suite) params.append("types", "SJ");
    if (typeQuery.Suite) params.append("types", "SS");

    params.append("nbAdulte", nbAdulteQuery);
    params.append("nbChildren", nbChildrensQuery);

    // API.post("ratepricing/checkoutrate", {
    //   start_date: localStorage.getItem("starting_date"),
    //   end_date: localStorage.getItem("ending_date"),
    // }) //, { params: params })
    //   .then((resp) => {
    //     setRateId(resp.data.rate.rate.id);
    //     setRooms(resp.data.rate.rate.details);
    //     setLoading(false);
    //   })
    //   .catch((e) => {});

    API.get("offerpricing/availableoffers")
      .then((resp) => {
        let offers = resp.data.offers;
        let details = offers.map((e) => {
          return { ...e.rate.details, offer_id: e.rate.id };
        });
        setOffersRooms(details.flat());
        setLoading(false);
      })
      .catch((e) => {});
  }, [typeQuery, nbAdulteQuery, nbChildrensQuery]);

  useEffect(() => {
    if (offersRooms !== null && rooms !== null) {
      if ([...offersRooms, ...rooms].length === 0) setLoading(false);
      setMessage(
        "No rooms available between the selected dates, please feel free to contact our reservation agents"
      );
    }
  }, [offersRooms, rooms]);

  return (
    <AnimatePresence exitBeforeEnter>
      <motion.div
        variants={pagesAnim}
        initial="hidden"
        animate="visible"
        exit="exit"
        className="checkrooms"
      >
        <Header />

        <div className="content">
          <div className="left">
            <CheckOutRateChambre
              setRooms={setRooms}
              setLoading={setLoading}
              setRateId={setRateId}
            />
            {loading ? (
              <>
                <Loader />
                <p>{message}</p>
              </>
            ) : (
              <div className="rms">
                {/* {console.log(packages)} */}
                {/* {packages?.map((e, i) => {
                  // const images = importAll(
                  //   require.context(
                  //     "../../../../api/uploads",
                  //     false,
                  //     /\.(png|jpe?g|svg)$/
                  //   )
                  // );
                  let rType = e.roomType;
                  return (
                    <>
                      {rType && (
                        <Room
                          id={e.id}
                          img={`${API_URL[process.env.NODE_ENV]}/uploads/${
                            JSON.parse(rType.images)[0]
                          }`}
                          type={rType.description}
                          price={e.prix_1Adulte}
                          delay={i}
                          key={i}
                          size={rType.size}
                          maxAdults={rType.maxAdulte}
                          maxChildrens={rType.maxChildren}
                          nbLitsQueen={rType.nbLitsQueen}
                        />
                      )}
                    </>
                  );
                })} */}
                {rooms?.map((e, i) => {
                  // const images = importAll(
                  //   require.context(
                  //     "../../../../api/uploads",
                  //     false,
                  //     /\.(png|jpe?g|svg)$/
                  //   )
                  // );
                  // let rType = e.roomType;
                  return (
                    <>
                      {e ? (
                        <Room
                          rate_id={rateId}
                          id={e.room.id}
                          img={`${UPLOAD_URL[process.env.NODE_ENV]}/rooms/${
                            e.room.pictures[0].filename
                          }`}
                          type={e.room.description}
                          price={e.price_1}
                          delay={i}
                          key={i}
                          size={e.room.size}
                          maxAdults={e.room.max_adults}
                          maxChildrens={e.room.max_children}
                          nbLitsQueen={e.room.nb_queen}
                        />
                      ) : (
                        <></>
                      )}
                    </>
                  );
                })}
                {offersRooms?.map((k, i) => {
                  // const images = importAll(
                  //   require.context(
                  //     "../../../../api/uploads",
                  //     false,
                  //     /\.(png|jpe?g|svg)$/
                  //   )
                  // );
                  // let rType = e.roomType;
                  let e = k["0"];
                  return (
                    <>
                      {e ? (
                        <Room
                          rate_id={k.offer_id}
                          id={e.room.id}
                          img={`${UPLOAD_URL[process.env.NODE_ENV]}/rooms/${
                            e.room.pictures[0].filename
                          }`}
                          type={e.room.description}
                          price={e.price_1}
                          delay={i}
                          key={i}
                          size={e.room.size}
                          maxAdults={e.room.max_adults}
                          maxChildrens={e.room.max_children}
                          nbLitsQueen={e.room.nb_queen}
                        />
                      ) : (
                        <></>
                      )}
                    </>
                  );
                })}
              </div>
            )}
          </div>
          <div className="right">
            <CheckBoxSelection
              typeQuery={typeQuery}
              setTypeQuery={setTypeQuery}
              {...cbs0}
            />
            <CheckBoxSelection
              nbAdultesQuery={nbAdulteQuery}
              setNbAdultesQuery={setNbAdulteQuery}
              {...cbs2}
            />
            <CheckBoxSelection
              nbChildrensQuery={nbChildrensQuery}
              setNbChildrensQuery={setNbChildrensQuery}
              {...cbs3}
            />
          </div>
        </div>
        <Footer />
      </motion.div>
    </AnimatePresence>
  );
};

export default CheckRooms;
