import "./checkoutrate.css";
import { Select } from "../BasicComponents";
import { useEffect, useRef } from "react";
import HotelDatepicker from "hotel-datepicker";
import { useState } from "react";
import API from "src/API";
import { useNavigate } from "react-router-dom";

const i18n = {
  selected: "Your stay:",
  night: "Night",
  nights: "Nights",
  button: "Close",
  clearButton: "Clear",
  submitButton: "Submit",
  "checkin-disabled": "Check-in disabled",
  "checkout-disabled": "Check-out disabled",
  "day-names-short": ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
  "day-names": [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ],
  "month-names-short": [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ],
  "month-names": [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ],
  "error-more": "Date range should not be more than 1 night",
  "error-more-plural": "Date range should not be more than %d nights",
  "error-less": "Date range should not be less than 1 night",
  "error-less-plural": "Date range should not be less than %d nights",
  "info-more": "Please select a date range of at least 1 night",
  "info-more-plural": "Please select a date range of at least %d nights",
  "info-range": "Please select a date range between %d and %d nights",
  "info-range-equal": "Please select a date range of %d nights",
  "info-default": "Please select a date range",
  "aria-application": "Calendar",
  "aria-selected-checkin": "Selected as check-in date, %s",
  "aria-selected-checkout": "Selected as check-out date, %s",
  "aria-selected": "Selected, %s",
  "aria-disabled": "Not available, %s",
  "aria-choose-checkin": "Choose %s as your check-in date",
  "aria-choose-checkout": "Choose %s as your check-out date",
  "aria-prev-month": "Move backward to switch to the previous month",
  "aria-next-month": "Move forward to switch to the next month",
  "aria-close-button": "Close the datepicker",
  "aria-clear-button": "Clear the selected dates",
  "aria-submit-button": "Submit the form",
};

const date = new Date();
const date2 = new Date();
date2.setDate(date.getDate() + 1);

localStorage.getItem("starting_date") ??
  localStorage.setItem(
    "starting_date",
    date.getDate() +
      " " +
      i18n["month-names-short"][date.getMonth()] +
      " " +
      date.getFullYear()
  );

localStorage.getItem("ending_date") ??
  localStorage.setItem(
    "ending_date",
    date2.getDate() +
      " " +
      i18n["month-names-short"][date2.getMonth()] +
      " " +
      date2.getFullYear()
  );

const CheckOutRateHome = () => {
  const elm = useRef(null);
  const ref = useRef();

  const [nbAdulte, setNbAdulte] = useState(null);
  const [nbChildren, setNbChildren] = useState(null);

  useEffect(() => {
    window.fecha = require("fecha");
    // API.get("pricing/available").then((resp) => {
    //   var datepicker = new HotelDatepicker(elm.current, {
    //     format: "DD MMM YYYY",
    //     infoFormat: "DD MMM YYYY",
    //     startDate: new Date(resp.data.min_start) || new Date(),
    //     endDate: new Date(resp.data.max_end) || false,
    //     // i18n,
    //   });

    //   // check code to fix date1 issue
    //   datepicker.setValue(
    //     localStorage.getItem("starting_date") +
    //       "- " +
    //       localStorage.getItem("ending_date")
    //   );
    //   datepicker.onSelectRange = () => {
    //     let start = datepicker.getValue().split(" - ")[0];
    //     // let s_day = start.split(" ")[0];
    //     // let s_month = i18n["month-names-short"].indexOf(start.split(" ")[1]) + 1;
    //     // let s_year = start.split(" ")[2];
    //     localStorage.setItem("starting_date", start);

    //     let end = datepicker.getValue().split(" - ")[1];
    //     // let e_day = end.split(" ")[0];
    //     // let e_month = i18n["month-names-short"].indexOf(end.split(" ")[1]) + 1;
    //     // let e_year = end.split(" ")[2];
    //     localStorage.setItem("ending_date", end);
    //   };
    // });

    API.get("ratepricing/deactivatedDates").then((resp) => {
      let disabledDates = resp.data.missingDates;

      var datepicker = new HotelDatepicker(elm.current, {
        format: "DD MMM YYYY",
        infoFormat: "DD MMM YYYY",
        startDate: new Date(),
        disabledDates: disabledDates,
        endDate: new Date(new Date().getFullYear(), 11, 31),
        // i18n,
      });

      // check code to fix date1 issue
      datepicker.setValue(
        localStorage.getItem("starting_date") +
          "- " +
          localStorage.getItem("ending_date")
      );
      datepicker.onSelectRange = () => {
        let start = datepicker.getValue().split(" - ")[0];
        // let s_day = start.split(" ")[0];
        // let s_month = i18n["month-names-short"].indexOf(start.split(" ")[1]) + 1;
        // let s_year = start.split(" ")[2];
        localStorage.setItem("starting_date", start);

        let end = datepicker.getValue().split(" - ")[1];
        // let e_day = end.split(" ")[0];
        // let e_month = i18n["month-names-short"].indexOf(end.split(" ")[1]) + 1;
        // let e_year = end.split(" ")[2];
        localStorage.setItem("ending_date", end);
      };
    });
  }, []);

  const navigate = useNavigate();

  // const checkRateHome = () => {
  //   let startDate = localStorage.getItem("starting_date");
  //   let finishDate = localStorage.getItem("ending_date");

  //   startDate = startDate.split(" ");
  //   finishDate = finishDate.split(" ");

  //   startDate[1] = i18n["month-names-short"].indexOf(startDate[1]) + 1;
  //   finishDate[1] = i18n["month-names-short"].indexOf(finishDate[1]) + 1;

  //   startDate = startDate[2] + "-" + startDate[1] + "-" + startDate[0];
  //   finishDate = finishDate[2] + "-" + finishDate[1] + "-" + finishDate[0];

  //   API.post("roomtype/check", {
  //     startDate,
  //     finishDate,
  //   })
  //     .then((resp) => {
  //       navigate("/chambres", { state: resp.data.data });
  //     })
  //     .catch((e) => {
  //       console.log(e);
  //     });
  // };

  useEffect(() => {
    ref.current.onclick = () => {
      // checkRateHome();
      let startDate = localStorage.getItem("starting_date");
      let finishDate = localStorage.getItem("ending_date");

      startDate = startDate.split(" ");
      finishDate = finishDate.split(" ");

      startDate[1] = i18n["month-names-short"].indexOf(startDate[1]) + 1;
      finishDate[1] = i18n["month-names-short"].indexOf(finishDate[1]) + 1;

      startDate = startDate[2] + "-" + startDate[1] + "-" + startDate[0];
      finishDate = finishDate[2] + "-" + finishDate[1] + "-" + finishDate[0];

      // API.post("roomtype/check", {
      //   startDate,
      //   finishDate,
      // })
      //   .then((resp) => {
      navigate("/chambres"); //, { state: resp.data.data });
      // })
      // .catch((e) => {
      //   console.log(e);
      // });
    };
  }, [navigate]);

  return (
    <div className="checkoutratehome">
      <input type="text" ref={elm} />
      <Select
        nb={2}
        name="Guests"
        list={[1, 2, 3]}
        setVal={setNbAdulte}
        val={nbAdulte}
        zIndex={2}
      />
      <Select
        nb={5}
        name="Childrens"
        list={[1, 2, 3]}
        setVal={setNbChildren}
        val={nbChildren}
        zIndex={1}
      />
      <button ref={ref}>CHECK RATES</button>
    </div>
  );
};

const CheckOutRateChambre = ({ setRooms, setRateId, setLoading }) => {
  const elm = useRef(null);
  const ref = useRef();

  const [refresh, setRefresh] = useState(0);

  useEffect(() => {
    window.fecha = require("fecha");
    // API.get("pricing/available").then((resp) => {
    //   var datepicker = new HotelDatepicker(elm.current, {
    //     format: "DD MMM YYYY",
    //     infoFormat: "DD MMM YYYY",
    //     startDate: new Date(resp.data.min_start) || new Date(),
    //     endDate: new Date(resp.data.max_end) || false,
    //     // i18n,
    //   });
    //   datepicker.setValue(
    //     localStorage.getItem("starting_date") +
    //       "- " +
    //       localStorage.getItem("ending_date")
    //   );
    //   datepicker.onSelectRange = () => {
    //     let start = datepicker.getValue().split(" - ")[0].split(" ");
    //     let s_day = start[0];
    //     let s_month = i18n["month-names-short"].indexOf(start[1]) + 1;
    //     let s_year = start[2];
    //     localStorage.setItem(
    //       "starting_date",
    //       s_month + "/" + s_day + "/" + s_year
    //     );

    //     let end = datepicker.getValue().split(" - ")[1].split(" ");
    //     let e_day = end[0];
    //     let e_month = i18n["month-names-short"].indexOf(end[1]) + 1;
    //     let e_year = end[2];
    //     localStorage.setItem(
    //       "ending_date",
    //       e_month + "/" + e_day + "/" + e_year
    //     );
    //   };
    // });

    API.get("ratepricing/deactivatedDates").then((resp) => {
      let disabledDates = resp.data.missingDates;

      var datepicker = new HotelDatepicker(elm.current, {
        format: "DD MMM YYYY",
        infoFormat: "DD MMM YYYY",
        startDate: new Date(),
        disabledDates: disabledDates,
        endDate: new Date(new Date().getFullYear(), 11, 31),
        // i18n,
      });
      datepicker.setValue(
        localStorage.getItem("starting_date") +
          "- " +
          localStorage.getItem("ending_date")
      );
      datepicker.onSelectRange = () => {
        let start = datepicker.getValue().split(" - ")[0].split(" ");
        let s_day = start[0];
        let s_month = i18n["month-names-short"].indexOf(start[1]) + 1;
        let s_year = start[2];
        localStorage.setItem(
          "starting_date",
          s_month + "/" + s_day + "/" + s_year
        );

        let end = datepicker.getValue().split(" - ")[1].split(" ");
        let e_day = end[0];
        let e_month = i18n["month-names-short"].indexOf(end[1]) + 1;
        let e_year = end[2];
        localStorage.setItem(
          "ending_date",
          e_month + "/" + e_day + "/" + e_year
        );

        setRefresh((prev) => prev + 1);
      };
    });
  }, []);

  // const navigate = useNavigate();

  // const checkRateRooms = () => {
  //   let startDate = localStorage.getItem("starting_date");
  //   let finishDate = localStorage.getItem("ending_date");

  //   startDate = startDate.split("/")[0].split(" ");
  //   finishDate = finishDate.split("/")[0].split(" ");

  //   startDate[1] = i18n["month-names-short"].indexOf(startDate[1]) + 1;
  //   finishDate[1] = i18n["month-names-short"].indexOf(finishDate[1]) + 1;

  //   startDate = startDate[2] + "-" + startDate[1] + "-" + startDate[0];
  //   finishDate = finishDate[2] + "-" + finishDate[1] + "-" + finishDate[0];

  //   API.post("roomtype/check", {
  //     startDate,
  //     finishDate,
  //   })
  //     .then((resp) => {
  //       setRooms([
  //         ...resp.data.data.currentRate[0].rateDetails,
  //         ...resp.data.data.packages[0].rateDetails,
  //       ]);
  //     })
  //     .catch((e) => {});
  // };

  // useEffect(() => {
  //   ref.current.onclick = () => {
  //     // checkRateRooms();
  //     let startDate = localStorage.getItem("starting_date");
  //     let finishDate = localStorage.getItem("ending_date");

  //     startDate = startDate.split("/")[0].split(" ");
  //     finishDate = finishDate.split("/")[0].split(" ");

  //     startDate[1] = i18n["month-names-short"].indexOf(startDate[1]) + 1;
  //     finishDate[1] = i18n["month-names-short"].indexOf(finishDate[1]) + 1;

  //     startDate = startDate[2] + "-" + startDate[1] + "-" + startDate[0];
  //     finishDate = finishDate[2] + "-" + finishDate[1] + "-" + finishDate[0];

  //     API.post("roomtype/check", {
  //       startDate,
  //       finishDate,
  //     })
  //       .then((resp) => {
  //         setRooms([
  //           ...resp.data.data.currentRate[0].rateDetails,
  //           ...resp.data.data.packages[0].rateDetails,
  //         ]);
  //       })
  //       .catch((e) => {});
  //   };
  // }, [setRooms]);

  useEffect(() => {
    API.post("ratepricing/checkoutrate", {
      start_date: localStorage.getItem("starting_date"),
      end_date: localStorage.getItem("ending_date"),
    }) //, { params: params })
      .then((resp) => {
        console.log(resp.data);
        setRateId(resp.data.rate.id);
        setRooms(resp.data.rate.details);
        setLoading(false);
      })
      .catch((e) => {});
  }, [refresh, setRateId, setRooms, setLoading]);

  return (
    <div className="checkoutratechambres">
      <input type="text" ref={elm} className="checkchambre_input" />
      <span className="checkchambre_span">1-night stay</span>
      <button className="checkchambre_button" ref={ref}>
        CHECK RATES {">"}
      </button>
    </div>
  );
};

export { CheckOutRateHome, CheckOutRateChambre };
