import "./paymenterror.css";
import Sad from "src/Assets/Icons/sad.png";
import { useState } from "react";
import { useEffect } from "react";
import API from "src/API";
import { useSearchParams } from "react-router-dom";
import Satim from "src/Assets/Images/satim.png";

const PaymentError = () => {
  const [searchParams] = useSearchParams();
  const [error, setError] = useState({});

  useEffect(() => {
    API.get(`reservations/status/${searchParams.get("orderId")}`)
      .then((resp) => {})
      .catch((err) => {
        setError(err.response.data.info);
      });
  }, []);

  return (
    <div className="paymenterror">
      <div className="errormessage">
        <img src={Sad} alt="we are sorry!" />
        <h3>Votre réservation n'a pas pu être effectuée.</h3>
        {error?.error_code === "0" &&
        error?.order_status === "3" &&
        error?.respCode === "00" ? (
          <span>
            Votre transaction a été rejetée/ Your transaction was rejected/ تم
            رفض معاملتك
          </span>
        ) : error?.resp_code_desc !== "" ? (
          <span> {error?.resp_code_desc}</span>
        ) : (
          <span>{error?.action_code_desc}</span>
        )}

        <div className="satim_call_us">
          <p>
            « en cas de problème , veuillez contacter le numéro vert de la satim
            »
          </p>
          <img src={Satim} alt="Satim contact info" />
        </div>

        <button
          onClick={() => {
            window.location.href = "/chambres";
          }}
        >
          Try Again
        </button>
      </div>
    </div>
  );
};

export default PaymentError;
