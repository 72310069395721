import { Footer, Header } from "src/Components";
import "./profile.css";
import { useEffect, useState } from "react";

import YellowStar from "src/Assets/Icons/yellowStar.png";
import GreyStar from "src/Assets/Icons/greyStar.png";
import FourStars from "src/Assets/Icons/fourStars.png";
import { useLocation } from "react-router-dom";
import { getUser } from "src/Utils/auth";
import API from "src/API";
import { API_URL, UPLOAD_URL } from "src/constants";
import { getNextKeyDef } from "@testing-library/user-event/dist/keyboard/getNextKeyDef";

const Profile = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const [selected, setSelected] = useState(
    queryParams.get("reservations") === "selected" ? 1 : 0
  );
  const [imgError, setImgError] = useState(false);
  const [confirmationEmail, setConfirmationEmail] = useState(false);
  const [reservations, setReservations] = useState([]);
  const [days, setDays] = useState(0);
  const [nights, setNights] = useState(0);
  const [step, setStep] = useState(0);
  const [editMode, setEditMode] = useState(false);

  const [birthdate, setBirthdate] = useState("");
  const [gender, setGender] = useState("");
  const [nationality, setNationality] = useState("");
  const [state, setState] = useState("");
  const [bio, setBio] = useState("");
  const [picture, setPicture] = useState("");

  const handleImgError = () => {
    setImgError(true);
  };

  if (!getUser()) window.location.href = "/";

  useEffect(() => {
    setBirthdate(new Date(getUser().birthdate));
    setGender(getUser().gender || "default");
    setBio(getUser().bio);
    API.get(
      "users/reservations/" +
        getUser().id +
        "/" +
        localStorage.getItem("user_token")
    )
      .then((resp) => {
        console.log(getUser());
        let reservations = resp.data.reservations;
        const chunkSize = 5;
        const result = Array.from(
          { length: Math.ceil(reservations.length / chunkSize) },
          (_, i) => reservations.slice(i * chunkSize, i * chunkSize + chunkSize)
        );
        console.log(result[0]);
        setReservations(result);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <div className="profile">
      <Header />
      <div className="section">
        <div className="avatar">
          <div className={editMode ? "editmode useravatar" : "useravatar"}>
            {" "}
            {editMode && picture.length === 0 ? (
              <input
                type="file"
                onChange={(e) => {
                  setPicture(e.target.files[0]);
                }}
              />
            ) : picture ? (
              <img src={URL.createObjectURL(picture)} alt="picture" />
            ) : (
              <img
                src={`${UPLOAD_URL[process.env.NODE_ENV]}/users/${
                  getUser()?.profile_picture?.filename
                }`}
                alt="user picture"
              />
            )}
          </div>
          {/* {picture ? <span className="filename">selected file : {picture.name}</span> : <></>} */}
          <span>
            Bakiri abdellah
            {getUser().status === "NOT_VERIFIED" ? (
              <img src={GreyStar} alt="confirm your account" />
            ) : (
              <img src={YellowStar} alt="account confirmed" />
            )}
          </span>
          {getUser().status === "NOT_VERIFIED" ? (
            confirmationEmail ? (
              <span className="green warning">Confirmation Email sent !</span>
            ) : (
              <span
                className="warning"
                onClick={() => {
                  API.post("emails/resendconfirmation", {
                    user_id: getUser().id,
                  }).then((resp) => {
                    setConfirmationEmail(true);
                  });
                }}
              >
                Please, check you email and confirm your account
              </span>
            )
          ) : (
            <></>
          )}
        </div>
        <div className="selection">
          <span
            className={selected === 0 ? "active" : ""}
            onClick={() => {
              queryParams.set("reservations", "");
              setSelected(0);
            }}
          >
            My informations
          </span>
          <span
            className={selected === 1 ? "active" : ""}
            onClick={() => {
              setSelected(1);
            }}
          >
            My reservations
          </span>
        </div>
        {selected === 0 ? (
          <div className="userinformations">
            <h3>Personal informations :</h3>
            <div className="line">
              <div className="userinfo">
                <span>Firstname</span>
                <input disabled readOnly defaultValue={getUser().firstname} />
              </div>
              <div className="userinfo">
                <span>Lastname</span>
                <input disabled readOnly defaultValue={getUser().lastname} />
              </div>
            </div>
            <div className="line">
              <div className="userinfo">
                <span>Birthdate</span>
                {editMode && !birthdate ? (
                  <input
                    value={birthdate}
                    readOnly={!editMode}
                    type="date"
                    className={
                      editMode && !getUser().birthdate ? "editmode" : ""
                    }
                    onChange={(e) => {
                      setBirthdate(e.target.value);
                    }}
                  />
                ) : (
                  <input disabled readOnly value={birthdate} />
                )}
              </div>
              <div className="userinfo">
                <span>Gender</span>
                {editMode && gender === "default" ? (
                  <select
                    defaultValue={gender}
                    className={editMode && !getUser().gender ? "editmode" : ""}
                    onChange={(e) => {
                      setGender(e.target.value);
                    }}
                  >
                    <option value="default" disabled>
                      Select your Gender
                    </option>
                    <option value="MALE">Male</option>
                    <option value="FEMALE">Female</option>
                  </select>
                ) : (
                  <input disabled readOnly value={gender} />
                )}
              </div>
            </div>
            {/* <div className="line">
              <div className="userinfo">
                <span>Nationality</span>
                <input
                  disabled={!editMode}
                  readOnly={!editMode}
                  placeholder="Algerian"
                  className={editMode ? "editmode" : ""}
                  onChange={(e) => {
                    setNationality(e.target.value);
                  }}
                />
              </div>
              <div className="userinfo">
                <span>State</span>
                <input
                  disabled={!editMode}
                  readOnly={!editMode}
                  placeholder="Setif"
                  className={editMode ? "editmode" : ""}
                  onChange={(e) => {
                    setState(e.target.value);
                  }}
                />
              </div>
            </div> */}
            <div className="userinfobio">
              <span>Bio</span>
              <textarea
                disabled={!editMode}
                readOnly={!editMode}
                className={editMode ? "editmode" : ""}
                placeholder="filling this field will help us serve you the best way"
                value={bio}
                onChange={(e) => {
                  setBio(e.target.value);
                }}
              />
            </div>
            {/* <h3>Residency informations :</h3>
            <div className="line">
              <div className="userinfo">
                <span>Country</span>
                <input disabled value="Algeria" />
              </div>
              <div className="userinfo">
                <span>State</span>
                <input disabled value="Setif" />
              </div>
            </div>
            <div className="line">
              <div className="userinfo">
                <span>Zip Code</span>
                <input disabled value="19000" />
              </div>
              <div className="userinfo"></div>
            </div>
            <div className="line">
              <div className="userinfo">
                <span>Street address</span>
                <input disabled value="Algeria" />
              </div>
            </div> */}
            <h3>Contact informations :</h3>
            <div className="line">
              <div className="userinfo">
                <span>Email</span>
                <input disabled readOnly defaultValue={getUser().email} />
              </div>
              <div className="userinfo">
                <span>Phone Number</span>
                <input disabled readOnly defaultValue={getUser().phone} />
              </div>
            </div>
            {editMode ? (
              <div className="profilebuttons">
                <button
                  onClick={() => {
                    setEditMode(false);
                  }}
                >
                  Cancel
                </button>{" "}
                <button
                  onClick={() => {
                    const formData = new FormData();

                    formData.append("birthdate", birthdate);
                    formData.append("bio", bio);
                    formData.append("gender", gender);
                    formData.append("pictures", picture);

                    API.put("users/" + getUser().id, formData, {
                      headers: "multipart/form-data",
                    })
                      .then((resp) => {
                        let user = resp.data.user;
                        console.log(user, resp.data);
                        localStorage.setItem(
                          "user_info",
                          JSON.stringify({ id: getUser().id, ...user })
                        );
                        setEditMode(false);
                      })
                      .catch((err) => {
                        console.log(err);
                      });
                  }}
                >
                  Save
                </button>
              </div>
            ) : (
              <button
                onClick={() => {
                  setEditMode(true);
                }}
              >
                Edit Profile
              </button>
            )}
          </div>
        ) : (
          <div className="userreservations">
            <span className="observation">
              The reservation page provides a comprehensive overview of your
              travel plans and rewards. You can view and manage all your
              reservations and keep track of your rewards balance with ease.
            </span>
            {reservations.length !== 0 && (
              <div className="steps">
                <button
                  className="previous"
                  onClick={() => {
                    if (step > 0) setStep((prev) => prev - 1);
                  }}
                >
                  {"<"} Previous
                </button>
                <div>
                  {reservations.length !== 0 ? (
                    reservations.map((_, i) => (
                      <span
                        className={i === step ? "step selected" : "step"}
                        onClick={() => {
                          setStep(i);
                        }}
                      >
                        {i + 1}
                      </span>
                    ))
                  ) : (
                    <></>
                  )}
                </div>
                <button
                  className="next"
                  onClick={() => {
                    console.log(reservations.length);
                    if (reservations.length - 1 > step)
                      setStep((prev) => prev + 1);
                  }}
                >
                  Next {">"}
                </button>
              </div>
            )}
            <div className="reservations">
              {reservations.length !== 0 ? (
                reservations[step].map((resr, i) => {
                  return (
                    <div className="reservation" key={i}>
                      {imgError ? (
                        <div className="img">
                          <img
                            src={`${UPLOAD_URL[process.env.NODE_ENV]}/rooms/${
                              resr.room.pictures[0].filename
                            }`}
                            alt="room picture"
                          />
                        </div>
                      ) : (
                        <img src="smqsdf" onError={handleImgError}></img>
                      )}
                      <div className="reservationinformations">
                        <span>{resr.room.description}</span>
                        <img src={FourStars} alt="Four stars" />
                        {/* <span>number of stars</span> */}
                        <span>
                          {Math.floor(
                            (new Date(resr.checkout_date).getTime() -
                              new Date(resr.checkin_date).getTime()) /
                              (1000 * 60 * 60 * 24)
                          ) + " nights"}
                          ,{" "}
                          {Math.floor(
                            (new Date(resr.checkout_date).getTime() -
                              new Date(resr.checkin_date).getTime()) /
                              (1000 * 60 * 60 * 24)
                          ) +
                            1 +
                            " days"}
                        </span>
                        <span>
                          {new Date(resr.checkin_date).toLocaleDateString(
                            "fr-FR",
                            { year: "numeric", month: "long", day: "numeric" }
                          )}
                        </span>
                        <span>
                          {parseFloat(resr.price).toLocaleString("fr-DZ", {
                            style: "currency",
                            currency: "DZD",
                          })}
                        </span>
                      </div>
                      <div className="rebook">
                        <span>+100 point</span>
                        <button>Rebook</button>
                      </div>
                    </div>
                  );
                })
              ) : (
                <></>
              )}
            </div>
            {reservations.length !== 0 && (
              <div className="steps">
                <button
                  className="previous"
                  onClick={() => {
                    setBio(getUser().bio);
                    if (step > 0) setStep((prev) => prev - 1);
                  }}
                >
                  {"<"} Previous
                </button>
                <div>
                  {reservations.length !== 0 ? (
                    reservations.map((_, i) => (
                      <span
                        className={i === step ? "step selected" : "step"}
                        onClick={() => {
                          setStep(i);
                        }}
                      >
                        {i + 1}
                      </span>
                    ))
                  ) : (
                    <></>
                  )}
                </div>
                <button
                  className="next"
                  onClick={() => {
                    console.log(reservations.length);
                    if (reservations.length - 1 > step)
                      setStep((prev) => prev + 1);
                  }}
                >
                  Next {">"}
                </button>
              </div>
            )}
          </div>
        )}
      </div>
      <Footer />
    </div>
  );
};

export default Profile;
